<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 h100vh">
    <TopPanel></TopPanel>
    <div id="image"></div>
    <section class="h-100 reset-password-box">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2 text-white h1">{{ $t('resetPassword.reset.title') }}</h1>
            <h4 class="text align-self-center p-2 text-white h3">{{ $t('resetPassword.reset.description') }}</h4>
            <div
                class="col-6 d-flex flex-column bg-white reset-password-container-inner rounded justify-content-center align-self-center">
              <div class="alert alert-danger" role="alert" v-for="e in state.error.value">
                {{ e }}
              </div>
              <div class="alert alert-success" role="alert" v-for="e in state.success.value">
                {{ e }}
              </div>
              <form @submit="onSubmit" v-if="state.state.value === 2">
                <div class="form-group-lg">
                  <label class="form-label ms-1" for="firstname">{{
                      $t('resetPassword.reset.form.input.label')
                    }}</label>
                  <input type="text" class="k-form-control-less-bold" id="firstname" aria-describedby="firstname"
                         required
                         :placeholder="$t('resetPassword.reset.form.input.placeholder')"
                         v-model="state.input.value">
                </div>
                <div class="form-group-lg mt-3">
                  <button type="submit" class="btn btn-primary" id="reset-password-button">{{
                      $t('resetPassword.reset.form.input.buttonText')
                    }}
                  </button>
                </div>
                <div class="form-check-lg mt-1 text-center">
                  <label class="form-label ms-1" for="exampleCheck1">
                    {{ $t('resetPassword.reset.form.login.first') }}
                    <router-link tag="li" class="col" class-active="active" to="/login" exact>
                      {{ $t('resetPassword.reset.form.login.login') }}
                    </router-link>
                  </label>
                </div>
              </form>
              <div v-else-if="[0,1].includes(state.state.value)">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                  </div>
                </div>
              </div>
              <div v-else-if="[3].includes(state.state.value)">
                <div class="alert alert-info" role="alert">
                  <h4 class="alert-heading">{{ $t('resetPassword.reset.configuration.disabled.title') }}</h4>
                  <p>{{ $t('resetPassword.reset.configuration.disabled.content') }}</p>
                  <hr>
                  <p class="mb-0">{{ $t('resetPassword.reset.configuration.disabled.description') }}</p>
                </div>
                <div class="form-group mt-4 text-secondary text-center">
                  <router-link tag="li" class="col" class-active="active" to="/login" exact>
                    {{ $t('resetPassword.reset.configuration.disabled.backToLogin.first') }}
                  </router-link>
                </div>
              </div>
              <div v-else-if="[4].includes(state.state.value)">
                <div class="alert alert-danger" role="alert">
                  <h4 class="alert-heading">{{ $t('resetPassword.reset.configuration.error.title') }}</h4>
                  <p>{{ $t('resetPassword.reset.configuration.error.content') }}</p>
                  <hr>
                  <p class="mb-0">{{ $t('resetPassword.reset.configuration.error.description') }}</p>
                </div>
              </div>
              <div v-else-if="[5].includes(state.state.value)">
                <div class="alert alert-success" role="alert">
                  <h4 class="alert-heading">{{ $t('resetPassword.reset.form.success.title') }}</h4>
                  <p>{{ $t('resetPassword.reset.form.success.content') }}</p>
                  <hr>
                  <p class="mb-0">{{ $t('resetPassword.reset.form.success.description') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import View from "@/components/Common/View";
import Loading from "@/components/Common/Loading";
import LoadingField from "@/components/Common/LoadingField";
import TopPanel from "@/components/Common/TopPanel";
import {onMounted, reactive, toRefs} from "vue";
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/ResetPassword/routes";
import {useI18n} from "vue-i18n";

export default {
  name: "ResetPassword",
  components: {LoadingField, Loading, View, TopPanel},
  setup() {
    const i18n = useI18n();
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);

    // 0 == > starting
    // 1 == > loading
    // 2 == > reset-password enabled
    // 3 == > reset-password disabled
    // 4 == > error
    // 5 == > mailSent

    const state = reactive(
        {
          input: '',
          error: [],
          success: [],
          state: 0,
          config: {}
        }
    );

    function onSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      state.error = [];

      if (state.input === '') {
        state.error.push(
            i18n.t('resetPassword.reset.form.errors.noInput')
        );
        return;
      }

      apiService.post(
          ROUTES.confirmPassword(),
          {
            input: state.input
          }
      )
          .then(
              (response) => {
                state.state =
                    response.data.responseCode === 8426136
                        ? 5
                        : 4;
              }).catch(
          (response) => {
            state.state = 4;
            state.error = ['error resetting password'];
          }
      )
    }

    function getConfig() {
      state.state = 1;
      apiService.get(
          ROUTES.getConfiguration()
      )
          .then(
              (r) => {
                state.state = r.data.resetEnabled
                    ? 2
                    : 3;
              }
          )
          .catch(
              () => {
                state.state = 4;
              }
          )
    }

    onMounted(
        () => {
          getConfig();
        }
    )
    return {
      state: toRefs(state),
      onSubmit: onSubmit,
    }
  }
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  width: 100vw;
  height: 50vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../assets/img/4.svg");
}

.h100vh {
  height: 100vh;
}

.reset-password-box {
  z-index: 99999;
}

.reset-password-container-inner {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-radius: 0.7rem;
  background-color: #F8F9FA;
  min-height: 15rem;
  box-shadow: 0px 2px 5.5px rgb(0 0 0 / 2%);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#reset-password-button {
  width: 100%;
}
</style>