<template>
  <View>
    <template v-slot:view-content>
      <div class="d-flex flex-row justify-content-between">
        <div class="container justify-content-center">
          <h3>{{ $t('dashboard.title') }}</h3>
          <div class="container text-secondary m-0 p-0">
            {{ $t('dashboard.description') }}
          </div>
        </div>
      </div>
    </template>
  </View>
</template>

<script>

import View from "@/components/Common/View";
import Card from "@/components/Common/Card";

export default {
  name: "Dashboard",
  components: {Card, View}
}
</script>

<style scoped>

</style>
