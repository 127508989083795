import {createApp} from 'vue'
import App from './App.vue'
import './assets/styles/style.scss';
import store from "./src/Config/Store/index";
import i18n from "./src/Config/I18N/index";
import {router} from "@/src/Config/Router";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
Sentry.init({
    app,
    dsn: require('./assets/file/config_' + process.env.NODE_ENV + '.json'),
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/app.keestash\.com/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store)
    .use(router)
    .use(i18n)
    .mount('#app')
