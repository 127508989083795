/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {ROUTES as METRIC_ROUTES} from "@/src/Config/Metric/routes";
import key from "@/src/Config/Common/Metric";

export class MetricService {
    constructor(apiService) {
        this.apiService = apiService;
    }

    measure(type, name, value) {
        this.apiService.post(
            METRIC_ROUTES.postMetricAdd(),
            {
                type: type,
                name: name,
                value: value,
                labels: []
            },
            {
                Authorization: 'Bearer ' + key.value
            }
        ).catch(
            () => {
                console.error('error sending metric')
            }
        )
    }
}
