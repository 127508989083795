/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
export class LoggerService {
    static DEBUG = 100;
    static INFO = 200;
    static NOTICE = 250;
    static WARNING = 300;
    static ERROR = 400;
    static CRITICAL = 500;
    static ALERT = 550;
    static EMERGENCY = 600;

    constructor(level) {
        this.level = level;
    }

    debug(message, context = {}) {
        if (false === this.isHandling(LoggerService.DEBUG)) {
            return;
        }
        console.debug(message, context);
    }

    isHandling(level) {
        return level >= this.level;
    }
}
