<template>
  <div>
    <div class="row flex-grow-1 justify-content-center align-self-center">
      <div class="col flex-grow-1 justify-content-center align-self-center">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="alert alert-info" v-if="state.isLDAP">{{ $t('login.LDAPInfo') }}
            </div>
            <h1>{{ $t('login.title') }}</h1>
            <div class="text-secondary">{{ $t('login.titleDescription') }}</div>
            <form class="mt-5" @submit.prevent="onSubmit">
              <div class="form-group">
                <label for="user" class="form-label ms-1">{{ $t('login.usernameLabel') }}</label>
                <input
                    type="text"
                    class="k-form-control-less-bold"
                    aria-describedby="emailHelp"
                    id="user"
                    :placeholder="$t('login.usernamePlaceholder')"
                    v-model="state.form.user"
                >
              </div>
              <div class="form-group mt-4">
                <label for="password" class="form-label ms-1">{{ $t('login.masterPasswordLabel') }}</label>
                <input
                    type="password"
                    class="k-form-control-less-bold"
                    id="password"
                    aria-describedby="emailHelp"
                    :placeholder="$t('login.masterPasswordPlaceholder')"
                    v-model="state.form.password"
                >
              </div>
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary" id="login-button">
                  {{ $t('login.login') }}
                </button>
              </div>
              <div class="form-group mt-4 text-secondary text-center">
                {{ $t('login.registerLink.first') }}
                <router-link tag="li" class="col" class-active="active" to="/register" exact>
                  {{ $t('login.registerLink.second') }}
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" aria-labelledby=""
         aria-hidden="true" ref="errorModal" data-bs-backdrop="false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content k-modal-content">
          <div class="modal-header">
            {{ $t('login.modal.title') }}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-4">
                {{ $t('login.modal.body') }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="state.error.modal.hide()">
              {{ $t('login.modal.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {API_SERVICE, APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Login/routes";
import {HEADER_NAME_TOKEN, HEADER_NAME_USER} from "@/src/Service/Api/ApiService";
import moment from "moment";
import {reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Modal} from "bootstrap";

export default {
  name: "Form",

  setup() {
    const store = useStore();
    const router = useRouter();
    const errorModal = ref(null);

    const state = reactive(
        {
          isLDAP: false,
          form: {
            user: '',
            password: '',
            remember: false
          },
          error: {
            modal: null
          }
        }
    );

    function onSubmit() {
      const containerFactory = new ContainerFactory();
      const container = containerFactory.create();
      /** @var {ApiService} apiService */
      const apiService = container.get(API_SERVICE);
      /** @var {AppStorage} appStorage */
      const appStorage = container.get(APP_STORAGE);

      apiService.post(
          ROUTES.getLoginSubmit(),
          {
            'user': state.form.user,
            'password': state.form.password
          }
      )
          .then((response) => {
            const primaryLanguage = response.data.settings.primaryLanguage;
            const saas = response.data.settings.isSaas;

            appStorage.storeAPICredentials(
                response.headers[HEADER_NAME_TOKEN],
                response.headers[HEADER_NAME_USER]
            );
            appStorage.storePrimaryLanguage(primaryLanguage);
            appStorage.storeSaas(saas);
            moment().format(primaryLanguage);
            store.commit('setUser', response.data.user);
            router.push('/dashboard');
          })
          .catch(
              () => {
                state.form.user = '';
                state.form.password = '';
                state.error.modal = new Modal(errorModal.value);
                state.error.modal.show();
              }
          )
    }

    return {
      state: state,
      onSubmit: onSubmit,
      errorModal: errorModal
    }
  },
}
</script>

<style scoped>

.modal-header {
  background-color: #081A51;
  color: white;
}

.modal {
  background: none;
}

#login-button {
  width: 100%;
}
</style>