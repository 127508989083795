/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {AppStorage} from "@/src/Service/Storage/AppStorage";
import {ApiService} from "@/src/Service/Api/ApiService";
import {Container} from "@/src/Container/Container";
import {MetricService} from "@/src/Service/Metric/MetricService";
import {NativeBase64Service} from "@/src/Service/Encryption/NativeBase64Service";
import {Base64Service} from "@/src/Service/Encryption/Base64Service";
import {CryptoJsHelper} from "@/src/Service/Encryption/CryptoJsHelper";
import {AesService} from "@/src/Service/Encryption/AesService";
import {DerivationService} from "@/src/Service/Encryption/DerivationService";
import {KeestashEncryptionService} from "@/src/Service/Encryption/KeestashEncryptionService";
import {LoggerService} from "@/src/Service/Logger/LoggerService";

export const APP_STORAGE = "appstorage.storage";
export const API_SERVICE = "service.api";
export const METRIC_SERVICE = "service.metric";
export const NATIVE_BASE64_SERVICE = "service.base64.native";
export const BASE64_SERVICE = "service.base64";
export const CRYPTO_JS_HELPER_SERVICE = "service.helper.js.crypto";
export const AES_SERVICE = "service.aesß";
export const DERIVATION_SERVICE = "service.derivation";
export const KEESTASH_ENCRYPTION_SERVICE = "service.encryption.keestash";
export const LOGGER_SERVICE = "service.logger";

export class ContainerFactory {

    constructor() {
        this.container = new Container();
    }

    create() {

        this.container.register(
            APP_STORAGE
            , () => {
                return new AppStorage();
            }
        );

        this.container.register(
            API_SERVICE
            , (container) => {
                return new ApiService(
                    container.get(APP_STORAGE)
                );
            }
        );
        this.container.register(
            METRIC_SERVICE
            , (container) => {
                return new MetricService(
                    container.get(API_SERVICE)
                );
            }
        );
        this.container.register(
            NATIVE_BASE64_SERVICE
            , () => {
                return new NativeBase64Service();
            }
        );
        this.container.register(
            BASE64_SERVICE
            , () => {
                return new Base64Service();
            }
        );
        this.container.register(
            AES_SERVICE
            , (container) => {
                return new AesService(
                    container.get(CRYPTO_JS_HELPER_SERVICE),
                    container.get(LOGGER_SERVICE)
                )
            }
        );
        this.container.register(
            DERIVATION_SERVICE
            , (container) => {
                return new DerivationService()
            }
        );
        this.container.register(
            CRYPTO_JS_HELPER_SERVICE
            , () => {
                return new CryptoJsHelper();
            }
        );
        this.container.register(
            KEESTASH_ENCRYPTION_SERVICE
            , (container) => {
                return new KeestashEncryptionService(
                    container.get(AES_SERVICE),
                    container.get(CRYPTO_JS_HELPER_SERVICE),
                    container.get(BASE64_SERVICE),
                    container.get(LOGGER_SERVICE),
                );
            }
        );
        this.container.register(
            LOGGER_SERVICE
            , (container) => {
                return new LoggerService();
            }
        );

        return this.container;
    }
}
