export const RESPONSE_NAME_USER_NOT_FOUND = 'found.not.user.name.response';
export const RESPONSE_NAME_USER_DISABLED = 'disabled.user.name.response';
export const RESPONSE_NAME_INVALID_CREDENTIALS = 'credentials.invalid.name.response';
export const RESPONSE_NAME_TERMS_AND_CONDITIONS_NOT_AGREED = 'agreed.not.conditions.and.terms.response';
export const RESPONSE_NAME_INVALID_PASSWORD = 'password.invalid';
export const RESPONSE_NAME_VALIDATE_USER = 'user.validate';
export const RESPONSE_NAME_ERROR_CREATING_USER = 'user.creating.error';
export const RESPONSE_NAME_USER_SUBSCRIPTION_CREATED = 'created.subscription.user';
export const RESPONSE_NAME_USER_CREATED = 'user.created';

export const RESPONSE_CODES = {
    [RESPONSE_NAME_USER_NOT_FOUND]: 785561,
    [RESPONSE_NAME_USER_DISABLED]: 786561,
    [RESPONSE_NAME_INVALID_CREDENTIALS]: 787561,
    // register/add
    [RESPONSE_NAME_TERMS_AND_CONDITIONS_NOT_AGREED]: 198378,
    [RESPONSE_NAME_INVALID_PASSWORD]: 45612365,
    [RESPONSE_NAME_VALIDATE_USER]: 5672344675,
    [RESPONSE_NAME_ERROR_CREATING_USER]: 67823415,
    [RESPONSE_NAME_USER_SUBSCRIPTION_CREATED]: 23491234345,
    [RESPONSE_NAME_USER_CREATED]: 67891234,
}