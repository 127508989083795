// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
import {createRouter, createWebHistory} from "vue-router";
import Login from "@/components/Login/Login";
import Dashboard from "@/components/Dashboard/Dashboard";
import {APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";
import Password from "@/components/Password/Password";
import PersonalInfo from "@/components/PersonalInfo/PersonalInfo";
import Settings from "@/components/Settings/Settings";
import Register from "@/components/Register/Register";
import ResultPage from "@/components/Subscription/Result/ResultPage";
import TOC from "@/components/TOC/TOC.vue";
import Privacy from "@/components/Privacy/Privacy.vue";
import Confirmation from "@/components/Register/Confirmation.vue";
import store from '../Store/index';
import ResetPassword from "@/components/ResetPassword/ResetPassword.vue";
import Confirm from "@/components/ResetPassword/Confirm.vue";
import PublicShare from "@/components/Password/PublicShare.vue";

const routes = [
    {path: '/', component: Login},
    {path: '/login', component: Login},
    {path: '/dashboard', component: Dashboard},
    {path: '/password', component: Password},
    {
        path: '/password/s/:hash',
        component: PublicShare,
        query: {
            hash: String
        }
    },
    {path: '/personal-info', component: PersonalInfo},
    // {path: '/password-health', component: PasswordHealth},
    {path: '/settings', component: Settings},
    {
        path: '/confirmation',
        component: Confirmation,
        props: route => ({token: route.query.token})
    },
    {path: '/register', component: Register},
    {
        path: '/subscriptionResult',
        component: ResultPage,
        props: route => ({query: route.query.session_id})
    },
    {path: '/terms-and-conditions', component: TOC},
    {path: '/privacy', component: Privacy},
    {path: '/reset-password', component: ResetPassword},
    {path: '/reset-password-confirm', component: Confirm}
];

/** @var {ContainerFactory} containerFactory */
const containerFactory = new ContainerFactory();
/** @var {Container} container */
const container = containerFactory.create();
/** @var {AppStorage} appStorage */
const appStorage = container.get(APP_STORAGE);

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(
        process.env.BASE_URL
    ),
    routes, // short for `routes: routes`
});

router.beforeEach(
    async (to) => {
        // redirect to login page if not logged in and trying to access a restricted page
        const publicPages = [
            '/login',
            '/register',
            'subscriptionResult',
            '/terms-and-conditions',
            '/privacy',
            '/confirmation',
            '/reset-password',
            '/password/s/:hash',
            '/reset-password-confirm',
        ];

        // Function to check if a route matches a public page
        const isPublicPage = (path) => {
            return publicPages.some(publicPage => {
                const regex = new RegExp(`^${publicPage.replace(/:\w+/g, '\\w+')}$`);
                return regex.test(path);
            });
        };

        const authRequired = !isPublicPage(to.path);
        if (authRequired && !appStorage.isValid()) {
            appStorage.clear();
            store.commit('clear');
            return '/login';
        }
    }
)
