<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 h100vh">
    <TopPanel></TopPanel>
    <div id="image"></div>
    <section class="h-100 register-box">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column align-items-center">
            <h1 class="text align-self-center p-2 text-white h1">{{ $t('register.header') }}</h1>
            <h4 class="text align-self-center p-2 text-white h3">{{ $t('register.description') }}</h4>
            <h4 class="text align-self-center p-2 text-white h6">{{ $t('register.slogan') }}</h4>
            <div class="d-flex flex-column bg-white register-container-inner rounded">
              <div class="alert alert-danger" role="alert" v-for="e in state.error.value" v-html="e">
              </div>
              <div class="alert alert-success" role="alert" v-for="e in state.success.value">
                {{ e }}
              </div>
              <div class="alert alert-info" role="alert" v-if="state.isSaas.value===true">
                {{ $t('register.configuration.isSaasInformation') }}
              </div>
              <form @submit="onSubmit" v-if="[2,5].includes(state.state.value)">
                <div class="form-group-lg">
                  <label class="form-label ms-1" for="firstname">{{ $t('register.form.firstName.label') }}</label>
                  <input type="text" class="k-form-control-less-bold" id="firstname" aria-describedby="firstname"
                         required
                         :placeholder="$t('register.form.firstName.placeholder')" v-model="state.user.value.firstname">
                </div>
                <div class="form-group-lg mt-3">
                  <label class="form-label ms-1" for="lastname">{{ $t('register.form.lastName.label') }}</label>
                  <input type="text" class="k-form-control-less-bold" id="lastname" aria-describedby="firstname"
                         required
                         :placeholder="$t('register.form.lastName.placeholder')" v-model="state.user.value.lastname">
                </div>
                <div class="form-group-lg mt-3">
                  <label class="form-label ms-1" for="username">{{ $t('register.form.userName.label') }}</label>
                  <input type="text" class="k-form-control-less-bold" id="username" aria-describedby="username" required
                         :placeholder="$t('register.form.userName.placeholder')" v-model="state.user.value.username">
                </div>
                <div class="form-group-lg mt-3">
                  <label class="form-label ms-1" for="email">{{ $t('register.form.email.label') }}</label>
                  <input type="email" class="k-form-control-less-bold" id="email" aria-describedby="email" required
                         :placeholder="$t('register.form.email.placeholder')" v-model="state.user.value.email">
                </div>
                <div class="form-group-lg mt-3">
                  <label class="form-label ms-1" for="password">{{ $t('register.form.password.label') }}</label>
                  <ViewablePassword v-model="state.user.value.password"
                                    :placeholder="$t('register.form.password.placeholder')"></ViewablePassword>
                </div>
                <div class="form-group-lg mt-3">
                  <label class="form-label ms-1" for="passwordRepeat">{{
                      $t('register.form.passwordRepeat.label')
                    }}</label>
                  <ViewablePassword v-model="state.user.value.passwordRepeat"
                                    :placeholder="$t('register.form.passwordRepeat.placeholder')"></ViewablePassword>
                </div>
                <div class="form-group-lg mt-3">
                  <button type="submit" class="btn btn-primary" id="register-button">
                    <div v-if="state.state.value === 2">

                      {{
                        $t('register.form.submit.buttonText')
                      }}
                    </div>
                    <div class="spinner-border spinner-border-sm" role="status" v-else></div>


                  </button>
                </div>
                <div class="form-check-lg mt-3">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" required
                         v-model="state.user.value.toc">
                  <label class="form-label ms-1" for="exampleCheck1">
                    {{ $t('register.form.toc.first') }}
                    <router-link tag="li" class="col" class-active="active" to="/terms-and-conditions" exact>
                      {{ $t('register.form.toc.tos') }}
                    </router-link>
                    {{ $t('register.form.toc.and') }}
                    <router-link tag="li" class="col" class-active="active" to="/privacy" exact>
                      {{ $t('register.form.toc.privacy') }}
                    </router-link>
                  </label>
                </div>
                <div class="form-check-lg mt-1 text-center">
                  <label class="form-label ms-1" for="exampleCheck1">
                    {{ $t('register.form.login.first') }}
                    <router-link tag="li" class="col" class-active="active" to="/login" exact>
                      {{ $t('register.form.login.login') }}
                    </router-link>
                  </label>
                </div>
              </form>
              <div v-else-if="[0,1].includes(state.state.value)">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                  </div>
                </div>
              </div>
              <div v-else-if="[3].includes(state.state.value)">
                <div class="alert alert-info" role="alert">
                  <h4 class="alert-heading">{{ $t('register.configuration.disabled.title') }}</h4>
                  <p>{{ $t('register.configuration.disabled.content') }}</p>
                  <hr>
                  <p class="mb-0">{{ $t('register.configuration.disabled.description') }}</p>
                </div>
                <div class="form-group mt-4 text-secondary text-center">
                  {{ $t('register.configuration.disabled.backToLogin.first') }}
                  <router-link tag="li" class="col" class-active="active" to="/login" exact>
                    {{ $t('register.configuration.disabled.backToLogin.second') }}
                  </router-link>
                </div>
              </div>
              <div v-else-if="[4].includes(state.state.value)">
                <div class="alert alert-danger" role="alert">
                  <h4 class="alert-heading">{{ $t('register.configuration.error.title') }}</h4>
                  <p>{{ $t('register.configuration.error.content') }}</p>
                  <hr>
                  <p class="mb-0">{{ $t('register.configuration.error.description') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import View from "@/components/Common/View";
import Loading from "@/components/Common/Loading";
import LoadingField from "@/components/Common/LoadingField";
import TopPanel from "@/components/Common/TopPanel";
import {onMounted, reactive, toRefs} from "vue";
import {
  AES_SERVICE,
  API_SERVICE,
  BASE64_SERVICE,
  ContainerFactory,
  CRYPTO_JS_HELPER_SERVICE,
  DERIVATION_SERVICE,
  LOGGER_SERVICE,
  METRIC_SERVICE
} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Register/routes";
import {useI18n} from "vue-i18n";
import {
  RESPONSE_CODES,
  RESPONSE_NAME_ERROR_CREATING_USER,
  RESPONSE_NAME_INVALID_PASSWORD,
  RESPONSE_NAME_TERMS_AND_CONDITIONS_NOT_AGREED,
  RESPONSE_NAME_VALIDATE_USER
} from "@/src/Service/Api/RESPONSE_CODES";
import {GAUGE} from "@/src/Config/Metric/types";
import ViewablePassword from "@/components/Common/ViewablePassword.vue";

export default {
  name: "Register",
  components: {ViewablePassword, LoadingField, Loading, View, TopPanel},
  setup() {
    const i18n = useI18n();
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    /** @var {MetricService} apiService */
    const metricService = container.get(METRIC_SERVICE);
    /** @var {CryptoJsHelper} cryptoJsHelper */
    const cryptoJsHelper = container.get(CRYPTO_JS_HELPER_SERVICE);
    /** @var {AesService} aesService */
    const aesService = container.get(AES_SERVICE);
    /** @var {Base64Service} base64Service */
    const base64Service = container.get(BASE64_SERVICE);
    /** @var {DerivationService} derivationService */
    const derivationService = container.get(DERIVATION_SERVICE);
    /** @var {LoggerService} loggerService */
    const loggerService = container.get(LOGGER_SERVICE);

    // 0 == > starting
    // 1 == > loading
    // 2 == > register enabled
    // 3 == > register disabled
    // 4 == > error
    // 5 == > form submitting

    const state = reactive(
        {
          user: {
            firstname: null,
            lastname: null,
            username: null,
            email: null,
            password: null,
            passwordRepeat: null,
            toc: false,
          },
          error: [],
          success: [],
          state: 0,
          isSaas: false,
          config: {}
        }
    );

    function onSubmit(event) {
      event.preventDefault();
      state.error = [];
      const previousState = state.state;
      state.state = 5;
      if (state.user.password !== state.user.passwordRepeat) {
        state.error.push(
            i18n.t('register.form.password.passwordsDoNotMatch')
        )
        ;
        state.state = previousState;
        return;
      }
      const derivation = derivationService.derive(state.user.password);
      const masterKey = cryptoJsHelper.generateRandomBytes();
      const encryptedAndEncodedMasterKey = aesService.encryptAES(derivation, masterKey);

      apiService.post(
          ROUTES.getRegisterAdd(),
          {
            first_name: state.user.firstname,
            last_name: state.user.lastname,
            user_name: state.user.username,
            email: state.user.email,
            password: state.user.password,
            password_repeat: state.user.passwordRepeat,
            terms_and_conditions: state.user.toc,
            key: encryptedAndEncodedMasterKey
          }
      ).then(
          (response) => {
            const url = (response.data.session || {}).url || null;
            if (url !== null) {
              // case RESPONSE_CODES[RESPONSE_NAME_USER_SUBSCRIPTION_CREATED]:
              //   break;

              window.location.href = response.data.session.url;
            } else {

              // case RESPONSE_CODES[RESPONSE_NAME_USER_CREATED]:
              //   break;

              state.success.push(
                  i18n.t('register.form.submit.registered')
              )
            }

            state.state = previousState;
          }).catch(
          (response) => {
            const results = response.response.data.results || [];
            switch (response.response.data.responseCode) {
              case RESPONSE_CODES[RESPONSE_NAME_TERMS_AND_CONDITIONS_NOT_AGREED]:
                state.error.push(i18n.t('register.form.submit.response.toc'));
                break;
              case RESPONSE_CODES[RESPONSE_NAME_INVALID_PASSWORD]:
                if (results.includes('PASSWORD_IS_EMPTY')) {
                  state.error.push(i18n.t('register.form.submit.response.missingPassword'));
                }
                if (results.includes('PASSWORD_REPEAT_IS_EMPTY')) {
                  state.error.push(i18n.t('register.form.submit.response.missingPasswordRepeat'));
                }
                if (results.includes('PASSWORD_AND_PASSWORD_REPEAT_ARE_NOT_EQUAL')) {
                  state.error.push(i18n.t('register.form.submit.response.passwordsDoNotMatch'));
                }
                if (results.includes('PASSWORD_MINIMUM_REQUIREMENTS_ARE_NOT_MET')) {
                  state.error.push(i18n.t('register.form.submit.response.minimumRequirements'));
                }
                break;
              case RESPONSE_CODES[RESPONSE_NAME_VALIDATE_USER]:
                if (results.includes('INVALID_FIRST_NAME')) {
                  state.error.push(i18n.t('register.form.submit.response.firstName'));
                }
                if (results.includes('INVALID_LAST_NAME')) {
                  state.error.push(i18n.t('register.form.submit.response.lastName'));
                }
                if (results.includes('INVALID_USER_NAME')) {
                  state.error.push(i18n.t('register.form.submit.response.userName'));
                }
                if (results.includes('USER_NAME_EXISTS')) {
                  state.error.push(i18n.t('register.form.submit.response.userNameTaken'));
                }
                if (results.includes('EMAIL_EXISTS')) {
                  state.error.push(i18n.t('register.form.submit.response.emailExists'));
                }
                if (results.includes('EMAIL_ADDRESS_IS_INVALID')) {
                  state.error.push(i18n.t('register.form.submit.response.emailAddressInvalid'));
                }
                if (results.includes('INVALID_PHONE')) {
                  state.error.push(i18n.t('register.form.submit.response.invalidPhone'));
                }
                if (results.includes('INVALID_WEBSITE')) {
                  state.error.push(i18n.t('register.form.submit.response.website'));
                }

                break;
              case RESPONSE_CODES[RESPONSE_NAME_ERROR_CREATING_USER]:
                break;
              default:
                state.error.push('unexpected error. PLease contact the admin');
            }
            state.state = previousState;
          }
      )
    }

    function getConfig() {
      state.state = 1;
      apiService.get(
          ROUTES.getConfiguration()
      )
          .then(
              (r) => {
                const registerEnabled = (true === r.data.registerEnabled);
                state.state = registerEnabled
                    ? 2
                    : 3;
                state.isSaas = true === r.data.isSaas;
              }
          )
          .catch(
              () => {
                state.state = 4;
              }
          )
    }

    onMounted(
        () => {
          getConfig();
          metricService.measure(GAUGE, 'registeropen', 1);
        }
    )
    return {
      state: toRefs(state),
      onSubmit: onSubmit,
    }
  }
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  width: 100vw;
  height: 50vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../assets/img/4.svg");
}

.h100vh {
  height: 100vh;
}

.register-box {
  z-index: 99999;
}

.register-container-inner {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-radius: 0.7rem;
  background-color: #F8F9FA;
  min-height: 15rem;
  box-shadow: 0px 2px 5.5px rgb(0 0 0 / 2%);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#register-button {
  width: 100%;
}
</style>
