<script setup>
import {reactive, toRefs, watch} from "vue";

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const state = reactive({
  field: {
    focused: false,
    value: props.modelValue, // Using a local reactive variable
  },
});

// Watch for external changes to `modelValue`
watch(
  () => props.modelValue,
  (newValue) => {
    state.field.value = newValue;
  }
);
</script>

<template>
  <input
      :type="state.field.focused ? 'text' : 'password'"
      class="form-control font-weight-light"
      :placeholder="placeholder"
    v-model="state.field.value"
    @input="emit('update:modelValue', state.field.value)"
    @focusin="state.field.focused = true"
    @focusout="state.field.focused = false"
  />
</template>

<style scoped>

</style>
