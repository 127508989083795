<template>
  <div class="container-fluid text-center align-middle flex-grow-1 h-100 justify-content-center d-flex flex-column">
    <img src="./../../assets/img/password/open-lock.svg" id="open-lock" class="text-center align-middle justify-content-center align-self-center">
    <div>Add your passwords in one secure place</div>
    <div>
<!--      <button class="btn btn-primary">Add Password</button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyPasswords"
}
</script>

<style scoped>
#open-lock {
  width: 90px;
}
</style>