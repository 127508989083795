<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-left flex-column" id="left-navbar">
    <div class="container-fluid d-flex flex-row flex-lg-column flex-xl-column flex-xxl-column">
      <a class="navbar-brand d-flex gap-2 align-self-start mb-lg-3 mb-xl-3 mb-xxl-3 mt-lg-3 mt-xl-3 mt-xxl-3"
         :href="this.frontend.path + '/password'">
        <img src="https://keestash.com/wp-content/uploads/2022/03/keestash-transparent-1.png" class="img-fluid"
             alt="Responsive image" id="logo">
        <p class="d-none d-lg-flex d-xl-flex d-xxl-flex text-white text-decoration-none">{{ $t('appName') }}</p>
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse flex-column justify-content-start align-self-start mt-xl-3 w-100"
           id="navbarSupportedContent">
        <ul class="navbar-nav flex-column align-self-start w-100">
          <li class="nav-item left-nav-item d-flex clickable pt-2 pb-2 flex-grow-1 align-self-start w-100"
              v-for="(item, index) in this.appMenu"
              :key="index"
              @click="onClick(item)">
            <div class="ms-3 d-flex justify-content-center align-items-center">
              <img :src=getIconByName(item.icon) class="bg-white k-icon">
            </div>
            <div class="nav-link text-white ms-3" href="#"> {{ $t('leftNavigation.appMenu.values.' + item.id) }}</div>
          </li>

        </ul>
        <div class="row mt-3 mb-3 text-white justify-content-start align-self-start ml-1">
          <div class="col">
            {{ $t('leftNavigation.options.name') }}
          </div>
        </div>
        <ul class="navbar-nav flex-column align-self-start w-100">
          <li class="nav-item left-nav-item d-flex clickable pt-2 pb-2 flex-grow-1 align-self-start w-100"
              v-for="(item, index) in this.options"
              :key="index"
              @click="onClick(item)">
            <div class="ms-3 d-flex justify-content-center align-items-center">
              <img :src=getIconByName(item.icon) class="bg-white k-icon">
            </div>
            <div class="nav-link text-white ms-3" href="#"> {{ $t('leftNavigation.options.values.' + item.id) }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-auto mb-3 text-white w-100 d-none d-lg-flex d-xl-flex d-xxl-flex">
      <div class="col">
        <div id="user-box">
          <div class="p-2 d-flex flex-column">
            <div>
              <img
                  :src="state.imagePath"
                  class="rounded" id="user-pic" :alt="user.name" width="30px">
            </div>
            <div class="fw-bold">
              {{ user.name }}
            </div>
            <div class="form-label">
              <small>{{ user.email }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, reactive} from 'vue';
import {API_SERVICE, APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES as COMMONROUTES} from "@/src/Config/Common/routes";
import json from "@/src/Config/Common/Env";
import {ROUTES} from "@/src/Config/Register/routes";

export default {
  name: "LeftNavigation",
  data() {
    return {
      appMenu: [
        {
          id: 'dashboard',
          icon: 'house',
          route: '/dashboard'
        },
        {
          id: 'password',
          icon: 'password',
          route: '/password'
        },
        // {
        //   id: 'payments',
        //   icon: 'card',
        //   route: 'payments'
        // },
        // {
        //   id: 'notes',
        //   icon: 'document'
        // },
        // {
        //   id: 'ids',
        //   icon: 'personal-id'
        // },
        // {
        //   id: 'share',
        //   icon: 'share'
        // },
        // {
        //   id: 'passwordHealth',
        //   icon: 'key',
        //   route: '/password-health'
        // },
      ],
      options: [
        {
          id: 'settings',
          icon: 'screw',
          route: '/settings'
        },
        {
          id: 'personalInfo',
          icon: 'user',
          route: '/personal-info'
        },
        {
          id: 'logout',
          icon: 'logout',
          route: '/logout'
        },
      ]
    }
  },
  methods: {
    getIconByName(name) {
      var images = require.context('../../assets/img/left-nav/', false, /\.svg/)
      return images('./' + name + ".svg");
    },
    onClick(item) {
      const containerFactory = new ContainerFactory();
      const container = containerFactory.create();
      /** @var {AppStorage} appStorage */
      const appStorage = container.get(APP_STORAGE);
      const apiService = container.get(API_SERVICE);
      const self = this;

      if (item.id !== 'logout') {
        self.$router.push(item.route);
        return;
      }

      apiService.post(
          ROUTES.logout()
      ).then(
          () => {
            appStorage.clear();
            self.$store.commit('clear');
            self.$router.push('/login');
          }
      )


    }
  },
  setup() {
    const store = useStore();
    const state = reactive(
        {
          imagePath: 'https://img.freepik.com/vecteurs-libre/homme-affaires-caractere-avatar-isole_24877-60111.jpg?w=2000'
        }
    )
    const user = computed(
        () => {
          return store.state.user;
        }
    )

    onMounted(
        () => {
          state.imagePath = COMMONROUTES.getAssetUrl(store.state.user.jwt);

          document.addEventListener(
              'listenToProfileImageChange',
              (event) => {
                state.imagePath = event.detail.jwt;
              }
          );
        }
    )
    return {
      user: user,
      state: state,
      frontend: json.frontend
    }
  }
}
</script>

<style scoped lang="scss">

#left-navbar {
  min-width: 267px;
}

#logo {
  width: 60px;
}

#user-box {
  background-color: #1B2B65;
  border-radius: 0.7rem;
  height: 12rem;
}

.left-nav-item {
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-bottom-left-radius: 0.7rem;
  border-top-left-radius: 0.7rem;

}

.left-nav-item:hover {
  background: #1B2B65;
}

.k-icon {
  border-radius: 0.7rem;
  padding: 0.4rem;
  width: 2rem;
}

#user-pic {
  width: 30px;
}
</style>
