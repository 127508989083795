/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import CryptoJS from "crypto-js";

export class AesService {
    static N = 2048;
    static r = 2;
    static p = 1;
    static ivLength = 16;

    constructor(cryptoJsHelper, loggerService) {
        this.cryptoJsHelper = cryptoJsHelper;
        this.loggerService = loggerService;
    }

    // decryptAES(secretKey, encryptedDataWordArray) {
    //     const secretKeyWordArray = this.cryptoJsHelper.toWordArray(secretKey);
    //     // Total length in bytes
    //     const totalLength = encryptedDataWordArray.sigBytes;
    //
    //     // Extract IV
    //     const iv = this.cryptoJsHelper.toWordArray(
    //         encryptedDataWordArray.words.slice(0, AesService.ivLength / 4),
    //         AesService.ivLength
    //     );
    //     // Extract HMAC Hash
    //     const hash = this.cryptoJsHelper.toWordArray(
    //         encryptedDataWordArray.words.slice(AesService.ivLength / 4, (AesService.ivLength + AesService.hashLength) / 4),
    //         AesService.hashLength
    //     );
    //
    //     // Extract CipherText
    //     const cipherText = this.cryptoJsHelper.toWordArray(
    //         encryptedDataWordArray.words.slice((AesService.ivLength + AesService.hashLength) / 4),
    //         totalLength - AesService.ivLength - AesService.hashLength
    //     );
    //
    //     console.log('IV: ', iv.toString())
    //     console.log('Hash: ', hash.toString())
    //     // Step 2: Generate Key by hashing the secret (AESService::HASH_ALGORITHM)
    //     // Generate Key by hashing the secret
    //     const key = CryptoJS.SHA256(secretKeyWordArray);
    //
    //     // Generate new HMAC to validate integrity
    //     const newHash = CryptoJS.HmacSHA256(cipherText, key);
    //     console.log('Comp-Hash: ', newHash.toString())
    //
    //     console.log(CryptoJS.enc.Hex.stringify(hash) === CryptoJS.enc.Hex.stringify(newHash))
    //     // Compare Hashes
    //     if (CryptoJS.enc.Hex.stringify(hash) !== CryptoJS.enc.Hex.stringify(newHash)) {
    //         throw new Error("Hashes do not match. Integrity check failed.");
    //     }
    //
    //     // console.log('start')
    //     // Step 5: Decrypt CipherText using AES-256-CBC
    //     // Decrypt CipherText using AES-256-CBC
    //     const decrypted = CryptoJS.AES.decrypt(
    //         {ciphertext: cipherText},
    //         key,
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );
    //
    //     // console.log('end')
    //     // console.log("decrypted: " + decrypted.toString())
    //     // Return decrypted string
    //     return decrypted.toString();
    // }

    encryptAES(secretKey, rawData) {
        const key = CryptoJS.SHA256(secretKey);
        const iv = CryptoJS.lib.WordArray.random(AesService.ivLength);
        const encrypted = CryptoJS.AES.encrypt(
            rawData,
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        const cipherText = encrypted.ciphertext;
        const hash = CryptoJS.HmacSHA256(cipherText, key);

        this.loggerService.debug("IV:", iv.toString());
        this.loggerService.debug("Hash (HMAC):", hash.toString());
        this.loggerService.debug("CipherText:", cipherText.toString());

        const finalEncrypted = iv.concat(hash).concat(cipherText);
        return CryptoJS.enc.Base64.stringify(finalEncrypted);
    }

    decryptAES(secretKey, encryptedDataWordArray) {
        const totalLength = encryptedDataWordArray.sigBytes;
        const iv = CryptoJS.lib.WordArray.create(
            encryptedDataWordArray.words.slice(0, AesService.ivLength / 4), // IV length is 16 bytes
            AesService.ivLength
        );
        const hash = CryptoJS.lib.WordArray.create(
            encryptedDataWordArray.words.slice(16 / 4, (AesService.ivLength + 32) / 4), // Hash length is 32 bytes (SHA-256)
            32
        );
        const cipherText = CryptoJS.lib.WordArray.create(
            encryptedDataWordArray.words.slice((AesService.ivLength + 32) / 4),
            totalLength - AesService.ivLength - 32
        );

        const key = CryptoJS.SHA256(secretKey);
        const newHash = CryptoJS.HmacSHA256(cipherText, key);

        this.loggerService.debug("IV:", iv.toString());
        this.loggerService.debug("Hash:", hash.toString());
        this.loggerService.debug("CipherText:", cipherText.toString());
        this.loggerService.debug("Recomputed Hash:", newHash.toString());

        if (CryptoJS.enc.Hex.stringify(hash) !== CryptoJS.enc.Hex.stringify(newHash)) {
            throw new Error('Hashes do not match. Integrity check failed.');
        }

        return CryptoJS.AES.decrypt(
            {ciphertext: cipherText},
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
    }

}
