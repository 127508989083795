<template>
  <div class="container-fluid d-flex flex-grow-1 flex-xl-row flex-lg-row flex-column">
    <div class="row">
      <LeftNavigation></LeftNavigation>
    </div>
    <div class="row flex-grow-1 ms-lg-4 ms-xl-4 ms-xxl-4 flex-column">
      <div class="col flex-shrink-1 flex-grow-0" style="z-index: 1">
        <TopNavigation
            :has-search="state.topNavigation.hasSearch"
        >
          <template v-slot:topNavigationContent>
            <slot name="topNavigationContent"></slot>
          </template>
        </TopNavigation>
      </div>
      <div class="col mt-4">
        <slot name="view-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNavigation from "@/components/Common/LeftNavigation";
import TopNavigation from "@/components/Common/TopNavigation";
import {reactive} from "vue";

export default {
  name: "View",
  components: {TopNavigation, LeftNavigation},
  props: {
    hasSearch: false
  },
  setup(props) {
    const state = reactive(
        {
          topNavigation: {
            hasSearch: props.hasSearch
          }
        }
    )
    return {
      state: state
    }
  }
}
</script>

<style scoped>

</style>