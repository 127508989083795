<template>
  <div class="card k-card">
    <div class="card-body">
      <div>
        <h5 class="card-title">
          <img src="./../../assets/img/left-nav/card.svg" width="35px">
          Payment Details
        </h5>
        <hr>
      </div>
      <div class="card-body p-0">
        <ul class="list-group border-0">
          <li class="list-group-item border-0 d-flex flex-row justify-content-between">
            <div>Credit Card</div>
            <div>+</div>
          </li>
          <li class="list-group-item border-0 d-flex flex-row justify-content-between">
            <div>Debit Card</div>
            <div>+</div>
          </li>
          <li class="list-group-item border-0 d-flex flex-row justify-content-between">
            <div>Paypal</div>
            <div>+</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
}
</script>

<style scoped lang="scss">
$width: 20rem;
$height: 18rem;
.k-card {
  width: $width;
  height: $height;

  background: #FFFFFF;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}
</style>