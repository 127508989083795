/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import store from '../../Config/Store/index';

export class KeestashEncryptionService {

    constructor(
        aesService,
        cryptoJsHelper,
        base64Service,
        loggerService
    ) {
        this.aesService = aesService;
        this.cryptoJsHelper = cryptoJsHelper;
        this.base64Service = base64Service;
        this.loggerService = loggerService;
    }

    decrypt(encrypted) {
        if (encrypted === '') {
            return '';
        }

        const decryptedKey = this.getDecryptedKey();

        const decrypted = this.aesService.decryptAES(
            decryptedKey,
            this.base64Service.decode(encrypted)
        );
        return this.cryptoJsHelper.stringify(decrypted);
    }

    encrypt(raw) {
        if (raw === '' || raw === null) {
            return '';
        }
        console.log(raw)
        const decryptedKey = this.getDecryptedKey();

        return this.aesService.encryptAES(
            decryptedKey,
            raw
        )
    }

    getDecryptedKey() {
        const derivationFromStore = store.state.derivation;
        const newDerivationFromStore = store.state.newDerivation;
        const keyFromStore = store.state.key;

        try {
            const decrypted = this.aesService.decryptAES(
                this.base64Service.decode(derivationFromStore),
                this.base64Service.decode(keyFromStore)
            );
            this.loggerService.debug('legacy encryption worked');
            return decrypted;
        } catch (e) {
            this.loggerService.debug('new encryption applied');
            return this.aesService.decryptAES(
                newDerivationFromStore,
                this.base64Service.decode(keyFromStore)
            );
        }
    }
}
