<template>
  <div
      ref="popper"
      v-show="isVisible"
      tabindex="-1"
      @contextmenu.capture.prevent
      @click.prevent="this.$emit('onClick', edge)"
  >
    <ul class="list-group">
      <slot name="edge" :edge="edge"/>
    </ul>
  </div>
</template>

<script>
import Popper from 'popper.js';

// @vue/component
export default {
  name: "ContextMenu",
  props: {
    boundariesElement: {
      type: String,
      default: 'body',
    },
  },
  components: {
    Popper,
  },
  data() {
    return {
      opened: false,
      edge: {},
    };
  },
  computed: {
    isVisible() {
      return this.opened;
    },
  },
  mounted() {
    let self = this;
    document.addEventListener('click', (e) => {
      if (
          self.$refs.popper !== undefined
          && self.$refs.popper !== null
          && self.$refs.popper.contains(e.target) === false
      ) {
        self.close();
      }
    })
  },
  methods: {
    open(evt, edge) {
      this.opened = true;
      this.edge = edge;

      if (this.popper) {
        this.popper.destroy();
      }

      this.popper = new Popper(this.referenceObject(evt), this.$refs.popper, {
        placement: 'right-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: document.querySelector(this.boundariesElement),
          },
        },
      });

      // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate();
      });

    },
    close() {
      this.opened = false;
      this.edge = null;
    },
    referenceObject(evt) {
      const left = evt.clientX;
      const top = evt.clientY;
      const right = left + 1;
      const bottom = top + 1;
      const clientWidth = 1;
      const clientHeight = 1;

      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom,
        };
      }

      return {
        getBoundingClientRect,
        clientWidth,
        clientHeight,
      }
    },
  },
  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
};

</script>

<style lang="scss" scoped>

</style>
