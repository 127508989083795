/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import json from './../../Common/Env/index';

const SETTINGS_USER_UPDATE_PROFILE_IMAGE = "/users/profile_image/update";
const SETTINGS_USER_EDIT = '/users/edit';
const USER_PROFILE_CONFIGURATION = '/users/profile/configuration';
const SETTINGS_USER_UPDATE_PASSWORD = '/users/update-password';

export const ROUTES = {

    getUpdateProfileImage: () => {
        return json.backend.path + SETTINGS_USER_UPDATE_PROFILE_IMAGE;
    },
    getProfileConfiguration: () => {
        return json.backend.path + USER_PROFILE_CONFIGURATION;
    },
    getUpdateUser: () => {
        return json.backend.path + SETTINGS_USER_EDIT;
    },
    updatePassword: () => {
        return json.backend.path + SETTINGS_USER_UPDATE_PASSWORD;
    },

}