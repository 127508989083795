<template>
  <div class="container-fluid d-xl-none d-xxl-none triangle-element d-flex flex-column justify-content-center">
    <div class="row">
      <div class="col justify-content-center">
        <img src="./../../assets/img/keestash-transparent.png" class="img-fluid mobile-logo"
             alt="background">
      </div>
    </div>
    <TopPanel class="position-absolute d-none d-md-block"></TopPanel>
  </div>

  <TopPanel class="position-absolute d-none d-xl-flex d-xxl-flex mt-5 z-index-9999"></TopPanel>

  <div class="container-fluid flex-column flex-grow-1 h-100 align-items-center z-index-9998 mt-5 mt-xl-0 mt-xxl-0">
    <div class="row justify-content-xl-evenly justify-content-xxl-evenly justify-content-center align-items-center">
      <div class="col-xl d-flex justify-content-center d-xl-flex d-xxl-flex flex-column d-lg-flex">
        <div class="row flex-column align-items-center">
          <div class="col col-xl-6 col-xxl-6 col-lg-6" v-if="state.isLDAP">
            <div class="alert alert-info">{{ $t('login.LDAPInfo') }}
            </div>
          </div>
          <div class="col col-xl-6 col-xxl-6 col-lg-6">
            <h1>{{ $t('login.title') }}</h1>
          </div>
          <div class="col col-xl-6 col-xxl-6 col-lg-6">
            <div class="text-secondary">{{ $t('login.titleDescription') }}</div>
          </div>
          <div class="col col-xl-6 col-xxl-6 col-lg-6 justify-content-center">
            <form class="mt-5" @submit.prevent="onSubmit">
              <div class="form-group">
                <label for="user" class="form-label ms-1">{{ $t('login.usernameLabel') }}</label>
                <input
                    type="text"
                    class="k-form-control-less-bold"
                    aria-describedby="emailHelp"
                    id="user"
                    :placeholder="$t('login.usernamePlaceholder')"
                    v-model="state.form.user"
                >
              </div>
              <div class="form-group mt-4">
                <label for="password" class="form-label ms-1">{{ $t('login.masterPasswordLabel') }}</label>
                <input
                    type="password"
                    class="k-form-control-less-bold"
                    id="password"
                    aria-describedby="emailHelp"
                    :placeholder="$t('login.masterPasswordPlaceholder')"
                    v-model="state.form.password"
                >
              </div>
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary" id="login-button">
                  {{ $t('login.login') }}
                </button>
              </div>
              <div class="form-group mt-4 text-secondary text-center">
                {{ $t('login.registerLink.first') }}
                <router-link tag="li" class="col" class-active="active" to="/register" exact>
                  {{ $t('login.registerLink.second') }}
                </router-link>
              </div>
              <div class="form-group text-secondary text-center">
                {{ $t('login.resetPasswordLink.first') }}
                <router-link tag="li" class="col" class-active="active" to="/reset-password" exact>
                  {{ $t('login.resetPasswordLink.second') }}
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl d-flex justify-content-top d-xl-flex d-xxl-flex flex-column d-none pr-0">
        <div class="container-fluid pr-0">
          <img src="./../../assets/img/4.svg" id="image" class="text-white img-fluid" alt="background">
          <div class="d-flex flex-row text-center justify-content-end centered">
            <img src="https://keestash.com/wp-content/uploads/2020/12/keestash-white-transparent-75-48.png"
                 class="img-fluid my-img"
                 alt="Keestash" width="130px" height="140px"
            >
            <div class="d-flex text-center align-items-center h1 ms-2" id="app-name">
              {{ $t('appName') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" aria-labelledby=""
         aria-hidden="true" ref="errorModal" data-bs-backdrop="false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content k-modal-content">
          <div class="modal-header">
            {{ $t('login.modal.title') }}
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-4">
                {{ $t('login.modal.body') }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="state.error.modal.hide()">
              {{ $t('login.modal.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPanel from "@/components/Common/TopPanel";
import Form from "@/components/Login/Form";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {
  AES_SERVICE,
  API_SERVICE,
  APP_STORAGE,
  BASE64_SERVICE,
  ContainerFactory,
  CRYPTO_JS_HELPER_SERVICE,
  DERIVATION_SERVICE,
  LOGGER_SERVICE,
  METRIC_SERVICE,
  NATIVE_BASE64_SERVICE
} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Login/routes";
import {HEADER_NAME_TOKEN, HEADER_NAME_USER} from "@/src/Service/Api/ApiService";
import moment from "moment/moment";
import {Modal} from "bootstrap";
import {GAUGE} from "@/src/Config/Metric/types";
import 'crypto-browserify';
import 'stream-browserify';

export default {
  name: "Login",
  components: {Form, TopPanel},
  setup() {
    const store = useStore();
    const router = useRouter();
    const errorModal = ref(null);

    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {AppStorage} appStorage */
    const appStorage = container.get(APP_STORAGE);
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    /** @var {MetricService} apiService */
    const metricService = container.get(METRIC_SERVICE);
    /** @var {NativeBase64Service} nativeBase64Service */
    const nativeBase64Service = container.get(NATIVE_BASE64_SERVICE);
    /** @var {Base64Service} nativeBase64Service */
    const base64Service = container.get(BASE64_SERVICE);
    /** @var {CryptoJsHelper} cryptoJsHelper */
    const cryptoJsHelper = container.get(CRYPTO_JS_HELPER_SERVICE);
    /** @var {AesService} aesService */
    const aesService = container.get(AES_SERVICE);
    /** @var {DerivationService} derivationService */
    const derivationService = container.get(DERIVATION_SERVICE);
    /** @var {LoggerService} loggerService */
    const loggerService = container.get(LOGGER_SERVICE);

    const state = reactive(
        {
          isLDAP: false,
          form: {
            user: '',
            password: '',
            remember: false
          },
          error: {
            modal: null
          }
        }
    );

    onMounted(
        () => {
          store.commit('clear');
          appStorage.clear();
          metricService.measure(GAUGE, 'loginopen', 1);

        }
    )

    function onSubmit() {

      apiService.post(
          ROUTES.getLoginSubmit(),
          {
            'user': state.form.user,
            'password': state.form.password
          }
      )
          .then((response) => {
            const primaryLanguage = response.data.settings.primaryLanguage;
            const saas = response.data.settings.isSaas;
            const key = response.data.key;
            const serverDerivation = response.data.derivation;
            const type = response.data.type;

            appStorage.storeAPICredentials(
                response.headers[HEADER_NAME_TOKEN],
                response.headers[HEADER_NAME_USER]
            );

            appStorage.storePrimaryLanguage(primaryLanguage);
            appStorage.storeSaas(saas);
            moment().format(primaryLanguage);

            const derivation = derivationService.derive(state.form.password);
            const decodedKey = base64Service.decode(key);

            let encryptedKey = null;
            try {
              encryptedKey = cryptoJsHelper.stringify(decodedKey)
            } catch (e) {
              encryptedKey = cryptoJsHelper.stringifyBinary(decodedKey);
            }

            store.commit('setUser', response.data.user);
            store.commit('setKey', encryptedKey);
            store.commit('setDerivation', serverDerivation);
            store.commit('setNewDerivation', derivation);
            router.push('/dashboard');
          })
          .catch(
              (e) => {
                console.log(e)
                state.form.user = '';
                state.form.password = '';
                state.error.modal = new Modal(errorModal.value);
                state.error.modal.show();
              }
          )
    }

    return {
      state: state,
      onSubmit: onSubmit,
      errorModal: errorModal
    }
  }
}
</script>

<style scoped>

.mobile-logo {
  height: 50px;
}

.triangle-element {
  width: 100vw;
  max-height: 100px;
  min-height: 100px;
  height: 15vh;
  background: #081A51;
}


/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 75%;
  color: white;
  transform: translate(-50%, -50%);
}

#image {
  width: 100%;
  /*height: 95%;*/
  /*background-size: cover;*/
  border-radius: 0 0 0 1vh;
  /*z-index: 0;*/
  /*background-repeat: no-repeat;*/
  background: #081A51;
  position: relative;
  text-align: center;
  color: white;
}

.my-img {
  height: 100%;
  width: 100%;
}


.modal-header {
  background-color: #081A51;
  color: white;
}

.modal {
  background: none;
}

#login-button {
  width: 100%;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute
  }

  .position-sm-relative {
    position: relative;
  }

  .z-index-1-sm {
    z-index: 1;
  }

}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute
  }

  .position-md-relative {
    position: relative;
  }

  .z-index-1-md {
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute
  }

  .position-lg-relative {
    position: relative;
  }

  .z-index-1-lg {
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute
  }

  .position-xl-relative {
    position: relative;
  }

  .z-index-1-xl {
    z-index: 1;
  }
}

@media (min-width: 1400px) {
  .position-xxl-absolute {
    position: absolute
  }

  .position-xxl-relative {
    position: relative;
  }

  .z-index-1-xxl {
    z-index: 1;
  }
}
</style>
