/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import CryptoJS from "crypto-js";

export class CryptoJsHelper {
    stringToUint8Array(derived) {
        return new Uint8Array(derived.split('').map(char => char.charCodeAt(0)));
    }

    toWordArray(value) {
        return CryptoJS.lib.WordArray.create(value)
    }

    generateRandomBytes() {
        const secret = CryptoJS.lib.WordArray.random(4096);
        return CryptoJS.enc.Base64.stringify(secret);
    }

    stringify(decrypted) {
        return CryptoJS.enc.Utf8.stringify(decrypted);
    }

    stringifyBinary(decrypted) {
        return CryptoJS.enc.Base64.stringify(decrypted); // Use Base64 to match the format from generateRandomBytes()
    }
}
