export class Entropizer {

    static defaultClasses = ['lowercase', 'uppercase', 'numeric', 'symbolsCommon', 'symbolsUncommon'];
    static symbolsCommon = ' ,.?!';
    static symbolsUncommon = '"£$%^&*()-_=+[]{};:\'@#~<>/\\|`¬¦';

    static classes = {
        lowercase: {regex: /[a-z]/, size: 26},
        uppercase: {regex: /[A-Z]/, size: 26},
        numeric: {regex: /[0-9]/, size: 10},
        symbols: {characters: Entropizer.symbolsCommon + Entropizer.symbolsUncommon},
        symbolsCommon: {characters: Entropizer.symbolsCommon},
        symbolsUncommon: {characters: Entropizer.symbolsUncommon},
        hexadecimal: {regex: /[a-fA-F0-9]/, size: 16}
    }

    constructor(options) {
        const classes = (options && options.classes) || Entropizer.defaultClasses;
        this.classes = [];
        for (let i = 0; i < classes.length; i++) {
            this.classes.push(typeof classes[i] === 'string' ? Entropizer.classes[classes[i]] : classes[i]);
        }
    }

    _evaluateClass(charClass, password) {
        let chars, i;
        if (charClass.regex && charClass.regex.test(password)) {
            return charClass.size;
        } else if (charClass.characters) {
            chars = charClass.characters;
            for (i = 0; i < chars.length; i++) {
                if (password.indexOf(chars.charAt(i)) > -1) {
                    return chars.length;
                }
            }
        }
        return 0;
    };

    evaluate(password) {
        let i, alphabetSize = 0;

        if (!password) {
            return 0;
        }

        // Find the alphabet of the password (the union of all the classes it uses)
        for (i = 0; i < this.classes.length; i++) {
            alphabetSize += this._evaluateClass(this.classes[i], password);
        }

        // If it's all unknown characters, return 0 instead of -Infinity
        if (alphabetSize === 0) {
            return 0;
        }

        return Math.log(alphabetSize) / Math.log(2) * password.length;
    };

}