/**
 * Keestash
 *
 * Copyright (C) <2024> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import scryptsy from "scryptsy";
import json from "@/src/Config/Common/Env";

export class DerivationService {
    derive(value) {
        const salt = json.frontend.derivation.salt;
        console.log(salt)
        const N = 2048;
        const r = 2;
        const p = 1;
        const keyLength = 32; // AES-256 requires 32-byte key
        return scryptsy(value, salt, N, r, p, keyLength);
    }
}
