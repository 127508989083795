<template>
  <div class="progress">
    <div class="progress-bar"
         :class="state.additionalClass.value"
         role="progressbar"
         :style="state.entropy.value === 0 ? 'width: 0%' : 'width: 100%'"
         :aria-valuenow="state.entropy.value"
         aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</template>

<script>
import {reactive, toRefs, watch} from "vue";
import {Entropizer} from "@/src/Service/Password/Entropizer";

export default {
  name: "Entropy",
  props: {
    value: {
      type: [String],
      required: true
    }
  },
  setup(props, context) {

    const state = reactive(
        {
          additionalClass: '',
          entropy: 0
        }
    )

    watch(() => props.value, (n, second) => {
      const entropizer = new Entropizer({
        classes: [
          Entropizer.classes.lowercase,
          Entropizer.classes.uppercase,
          Entropizer.classes.numeric,
          Entropizer.classes.symbols
        ]
      });

      state.entropy = entropizer.evaluate(n);

      if (state.entropy <= 50) {
        state.additionalClass = 'bg-danger';
        return;
      }

      if (state.entropy <= 80) {
        state.additionalClass = "bg-warning";
        return
      }
      state.additionalClass = 'bg-success';
    });

    return {
      state: toRefs(state),
    }
  }
}
</script>

<style scoped>

</style>