<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 h100vh">
    <TopPanel></TopPanel>
    <div id="image"></div>
    <section class="h-100 resultpage-box">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2">{{ $t('resetPassword.confirm.title') }}</h1>
            <h4 class="text align-self-center p-2">{{ $t('resetPassword.confirm.subTitle') }}</h4>
            <div
                class="d-flex flex-column bg-white align-items-center text-center register-container-inner rounded justify-content-center">
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="isLoadingState()">
                <div class="spinner-border" role="status"></div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.state.value === 2"
              >
                <h1>{{ $t('resetPassword.confirm.userFound.title') }}</h1>
                <div class="text-primary">
                  <p>{{
                      $t('resetPassword.confirm.userFound.subTitle').replace('%s', state.userFound.value.user.name)
                    }}</p>
                </div>
                <form class="d-flex flex-column flex-grow align-items-stretch width-100 container">
                  <div class="form-group">
                    <label>{{ $t('resetPassword.confirm.userFound.form.password') }}</label>
                    <input type="email" class="form-control"
                           :placeholder="$t('resetPassword.confirm.userFound.form.password')"
                           v-model="state.userFound.value.form.password">
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">{{
                        $t('resetPassword.confirm.userFound.form.passwordRepeat')
                      }}</label>
                    <input type="password" class="form-control"
                           :placeholder="$t('resetPassword.confirm.userFound.form.passwordRepeat')"
                           v-model="state.userFound.value.form.passwordRepeat">
                  </div>
                  <button class="btn btn-primary mt-3" @click="resetPassword">{{
                      $t('resetPassword.confirm.userFound.form.buttonText')
                    }}
                  </button>
                </form>

              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="isErrorState()"
              >
                <h1>{{ state.userNotFound.value.errors.message }}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-slash-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                </svg>
                <div class="text-primary">
                  <p>{{ state.userNotFound.value.errors.detail }}</p>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.state.value === 5"
              >
                <h1>{{ $t('resetPassword.confirm.passwordChanged.success.title') }}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <div class="text-primary">
                  <p>{{ $t('resetPassword.confirm.passwordChanged.success.description') }}</p>
                </div>
                <router-link tag="li" class="col" class-active="active" to="/login" exact>
                  {{ $t('resetPassword.confirm.passwordChanged.success.buttonText') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>


<script>
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import TopPanel from "@/components/Common/TopPanel.vue";
import {ROUTES} from "@/src/Config/Register/routes";
import {useI18n} from "vue-i18n";

export default {
  name: "Confirm",
  components: {TopPanel},

  setup() {
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    const route = useRoute();
    const hash = route.query['reset-hash'] || null;
    const i18n = useI18n();

    // 0 --> initialized
    // 1 --> loading
    // 2 --> user found
    // 3 --> user not found
    // 4 --> submitted
    // 5 --> password changed
    // 6 --> submitError

    const state = reactive(
        {
          state: 0,
          userFound: {
            form: {
              password: '',
              passwordRepeat: ''
            },
            user: {
              name: ''
            }
          },
          userNotFound: {
            errors: {
              message: "",
              detail: "",
            }
          }
        }
    );

    function isLoadingState() {
      return state.state === 1
          || state.state === 4;
    }

    function isErrorState() {
      return state.state === 3
          || state.state === 6;
    }

    function resetPassword() {

      if (state.userFound.form.password !== state.userFound) {
        state.state = 6;
      }

      state.state = 4;
      apiService.post(
          ROUTES.confirmPasswordReset(),
          {
            hash: hash,
            password: state.userFound.form.password
          }
      ).then(
          () => {
            state.state = 5;
          }
      ).catch(
          () => {
            state.state = 6;
            state.userNotFound.errors.message = i18n.t('resetPassword.confirm.submitError.errors.message');
            state.userNotFound.errors.detail = i18n.t('resetPassword.confirm.submitError.errors.detail');
          }
      )
    }

    function retrieve() {
      apiService.get(
          ROUTES.retrievePasswordReset(hash)
      )
          .then((r) => {
            state.state = 2;
            state.userFound.user = r.data.user;
          })
          .catch((r) => {
            state.state = 3;
            state.userNotFound.errors.message = i18n.t('resetPassword.confirm.userNotFound.errors.message');
            state.userNotFound.errors.detail = i18n.t('resetPassword.confirm.userNotFound.errors.detail');
          })
    }

    onMounted(
        () => {
          state.state = 1;
          retrieve();
        }
    )

    return {
      state: toRefs(state),
      resetPassword: resetPassword,
      isLoadingState: isLoadingState,
      isErrorState: isErrorState
    }
  }
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  width: 100vw;
  height: 50vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../assets/img/4.svg");
}

.h100vh {
  height: 100vh;
}

.register-box {
  z-index: 99999;
}

.register-container-inner {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-radius: 0.1rem;
  background-color: #F8F9FA;
  min-height: 30rem;
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#register-button {
  width: 100%;
}

</style>