<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0">
    <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 bg-primary">
      <TopPanel class="mb-3"></TopPanel>
    </div>
    <section class="h-100 register-box mt-5">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2 h1">{{ $t('privacy.header') }}</h1>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('privacy.description') }}
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.dataRecording.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.1.question') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.1.answer') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.2.question') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.2.answer') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.3.question') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.3.answer') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.4.question') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.4.answer') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.5.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.5.content') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.6.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.6.content') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.7.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.7.content') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.dataRecording.8.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.dataRecording.8.content') }}</h4>
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.privacy.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.privacy.content') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.responsibleParty.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.responsibleParty.content') }}</h4>
              <ul style="list-style: none">
                <li>{{ $t('privacy.responsibleParty.party.name')}}</li>
                <li>{{ $t('privacy.responsibleParty.party.address1')}}</li>
                <li>{{ $t('privacy.responsibleParty.party.address2')}}</li>
                <li>{{ $t('privacy.responsibleParty.party.phone')}}</li>
                <li>{{ $t('privacy.responsibleParty.party.email')}}</li>
              </ul>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.ssl.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.ssl.content') }}</h4>
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <h1 class="text p-2 h5 font-weight-bold">{{ $t('privacy.payment.title') }}</h1>
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.payment.content') }}</h4>
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.privacy.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.privacy.content') }}</h4>
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.customerContractData.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.customerContractData.content') }}</h4>
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.contractorData.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.contractorData.content') }}</h4>
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('privacy.changes.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              <h4 class="text align-self-start text-start p-2 h6">{{ $t('privacy.changes.content') }}</h4>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import TopPanel from "@/components/Common/TopPanel.vue";

export default {
  name: "Privacy",
  components: {TopPanel}
}
</script>

<style scoped>

</style>