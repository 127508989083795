<template>
  <div
      class="row align-self-center d-flex flex-column justify-content-center align-items-center w-50">
    <div id="topPanel" class="d-flex flex-row w-100 justify-content-between w-100 p-3">
      <div id="logo">
        <img src="https://keestash.com/wp-content/uploads/2020/12/keestash_logo_inverted_no_name-small-2.png"
             class="img-fluid"
             id="keestash-logo"
             alt="Keestash" width="60px">
        <span class="ml-1 d-sm-none">
        {{ $t('appName') }}
          </span>
      </div>
      <div id="buttons" class="d-flex flex-row justify-content-between">
        <button class="buttonBase me-2" id="contactUsButton"
                @click.prevent="redirectToUcarSolutions">{{ $t('topPanel.contactUs') }}
        </button>
<!--        <button class="buttonBase" id="downloadButton" @click.prevent="redirectToGitHub">{{-->
<!--            $t('topPanel.download')-->
<!--          }}-->
<!--        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopPanel",
  setup() {
    function redirectToUcarSolutions() {
      window.location.href = 'https://ucar-solutions.de/kontakt/'
    }

    function redirectToGitHub() {
      window.location.href = 'https://github.com/keestash/server'
    }

    return {
      redirectToUcarSolutions: redirectToUcarSolutions,
      redirectToGitHub: redirectToGitHub
    }
  }
}
</script>

<style scoped>

.buttonBase {
  border-radius: 34.5px;
  padding: 0px;
}

#topPanel {
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%);
  box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(21px);
  border-radius: 15px;
}

#contactUsButton {
  box-sizing: border-box;
  border: 1px solid #2D3748;
  border-radius: 34.5px;
}

#downloadButton {
  background: #2D3748;
  border-radius: 34.5px;
  color: white;
  border: 0;
}

#keestash-logo {
  width: 60px;
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute
  }

  .position-sm-relative {
    position: relative;
  }
}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute
  }

  .position-md-relative {
    position: relative;
  }
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute
  }

  .position-lg-relative {
    position: relative;
  }
}

@media (min-width: 1200px) {
  .position-xl-absolute {
    position: absolute
  }

  .position-xl-relative {
    position: relative;
  }
}

@media (min-width: 1400px) {
  .position-xxl-absolute {
    position: absolute
  }

  .position-xxl-relative {
    position: relative;
  }
}

</style>