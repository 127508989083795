<template>
  <li class="list-group-item clickable" @click="this.$emit('onClick')">
    <slot/>
  </li>
</template>

<script>
export default {
  name: "ContextMenuItem"
}
</script>

<style lang="scss" scoped>

</style>

