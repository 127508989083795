<template>
  <View>
    <template v-slot:view-content>
      <div>
        <div class="container bg-white">
          <div class="row">
            <div class="col-3">
              <nav class="settings-container nav flex-column">
                <a
                    class="nav-link"
                    :class="state.selected.value === 'passwordhealth' ? 'active' : ''"
                    href="#"
                    @click="state.selected.value = 'passwordhealth'">
                  {{ $t('settings.passwordhealth.title') }}
                </a>
                <a
                    class="nav-link"
                    :class="state.selected.value === 'subscription' ? 'active' : ''"
                    href="#"
                    @click="state.selected.value = 'subscription'"
                    v-if="isSaas">
                  {{ $t('settings.subscription.title') }}
                </a>
                <a
                    class="nav-link"
                    :class="state.selected.value === 'ldap' ? 'active' : '' "
                    href="#"
                    @click="state.selected.value = 'ldap'"
                    v-if="isLdap">
                  {{ $t('settings.ldap.title') }}
                </a>
              </nav>
            </div>
            <div class="col">
              <div class="container settings-container" v-if="state.selected.value === 'passwordhealth'">
                <div class="row p-3">

                  <div class="d-flex flex-column">
                    <h3 class="docs-title h3">{{ $t('settings.passwordhealth.detail.title') }}</h3>
                    <div class="h5 mb-4 text-body-secondary" id="content">{{
                        $t('settings.passwordhealth.detail.body')
                      }}
                    </div>

                  </div>
                  <form>
                    <LoadingField class="profile-loading-long"
                                  v-if="state.passwordhealth.value.loading"></LoadingField>

                    <div class="form-check" v-else>
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                             v-model="state.passwordhealth.value.value"
                             @change="onCheckboxClick">
                      <label class="form-check-label" for="flexCheckDefault">
                        {{ $t('settings.passwordhealth.detail.breachCheck') }}
                      </label>
                    </div>
                  </form>


                </div>
              </div>
              <div class="container settings-container" v-if="state.selected.value === 'ldap' && !isSaas">
                <div class="container-fluid mt-3 mb-3">
                  <div class="text-primary">{{ $t("settings.ldap.title") }}</div>
                  <div class="text-secondary">
                    <small>{{ $t("settings.ldap.description") }}</small>
                  </div>
                </div>
                <form>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <label for="ldap-host">{{ $t("settings.ldap.form.host.label") }}</label>
                    <input type="text" class="form-control" id="ldap-host" aria-describedby="ldaphost"
                           :placeholder="$t('settings.ldap.form.host.placeholder')"
                           v-model="state.connection.value.host">
                    <small id="ldaphost" class="form-text text-muted">{{
                        $t("settings.ldap.form.host.description")
                      }}</small>
                  </div>
                  <LoadingField class="settings-loading" v-else></LoadingField>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <label for="port">{{ $t("settings.ldap.form.port.label") }}</label>
                    <input type="text" class="form-control" id="port"
                           :placeholder="$t('settings.ldap.form.port.placeholder')"
                           v-model="state.connection.value.port">
                  </div>
                  <LoadingField class="settings-loading" v-else></LoadingField>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <label for="user-dn">{{ $t("settings.ldap.form.userDn.label") }}</label>
                    <input type="text" class="form-control" id="user-dn"
                           :placeholder="$t('settings.ldap.form.userDn.placeholder')"
                           v-model="state.connection.value.user_dn">
                    <small id="ldaphost" class="form-text text-muted">{{
                        $t("settings.ldap.form.userDn.description")
                      }}</small>
                  </div>
                  <LoadingField class="settings-loading" v-else></LoadingField>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <label for="user-dn-password">{{ $t("settings.ldap.form.userDnPassword.label") }}</label>
                    <input type="password" class="form-control" id="user-dn-password"
                           :placeholder="$t('settings.ldap.form.userDnPassword.placeholder')"
                           v-model="state.connection.value.password">
                  </div>
                  <LoadingField class="settings-loading" v-else></LoadingField>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <label for="base-dn">{{ $t("settings.ldap.form.baseDn.label") }}</label>
                    <input type="text" class="form-control" id="base-dn"
                           :placeholder="$t('settings.ldap.form.baseDn.placeholder')"
                           v-model="state.connection.value.base_dn">
                    <small id="ldaphost" class="form-text text-muted">{{
                        $t("settings.ldap.form.baseDn.description")
                      }}</small>
                  </div>
                  <LoadingField class="settings-loading" v-else></LoadingField>
                  <div class="form-group mb-3" v-if="!state.loading.value">
                    <div class="alert alert-info">{{ getLdapInfo() }}
                    </div>
                  </div>
                  <LoadingField class="settings-loading mb-3" v-else></LoadingField>
                  <button type="submit" class="btn btn-primary mb-3"
                          v-if="!state.loading.value && state.connection.value.host.length === 0">
                    {{ $t("settings.ldap.form.add") }}
                  </button>
                  <button type="submit" class="btn btn-danger mb-3" v-if="state.connection.value.host.length > 0"
                          @click.prevent="remove">
                    {{ $t("settings.ldap.form.remove") }}
                  </button>
                  <button type="submit" class="btn btn-warning mb-3" v-if="state.connection.value.host.length > 0"
                          @click.prevent="deactivate">
                    {{ $t("settings.ldap.form.deactivate") }}
                  </button>
                </form>
              </div>
              <div class="container settings-container" v-if="state.selected.value === 'subscription' && isSaas">
                <div class="container-fluid mt-3 mb-3">
                  <div class="text-primary">{{ $t('settings.subscription.form.title') }}</div>
                  <div class="text-secondary">
                    <small>{{ $t('settings.subscription.form.description') }}</small>
                  </div>
                  <div class="container">
                    <button type="button" class="btn btn-link" @click="cancelSubscription">
                      {{ $t('settings.subscription.form.cancel.button') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" tabindex="-1" aria-labelledby=""
             aria-hidden="true" ref="cancelSubscriptionModal">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content k-modal-content">
              <div class="modal-header">
                <img src="./../../assets/img/left-nav/password-white.svg" class="icon-image"
                     alt="add password"
                >
                <h5 class="modal-title">
                  {{ $t('settings.subscription.form.cancel.modal.title') }}
                </h5>
                <div @click="state.subscription.value.cancellation.modal.hide()" class="clickable">
                  <img src="./../../assets/img/common/x-lg-white.svg" class="icon-image"
                       alt="close dialog"
                  >
                </div>
              </div>
              <div class="modal-body">
                {{ $t('settings.subscription.form.cancel.modal.text') }}
                <div class="alert alert-warning" role="alert"
                     v-if="state.subscription.value.cancellation.confirmCancel !== ''">
                  {{ state.subscription.value.cancellation.confirmCancel }}
                </div>
              </div>
              <div class="modal-footer">
                <div class="spinner-border" role="status" v-if="state.subscription.value.cancellation.loading"></div>
                <button type="button" class="btn btn-link" @click="requestCancel">
                  {{ $t('settings.subscription.form.cancel.modal.cancel') }}
                </button>
                <button type="button" class="btn btn-success"
                        @click="state.subscription.value.cancellation.modal.hide()">
                  {{ $t('settings.subscription.form.cancel.modal.back') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" tabindex="-1" aria-labelledby=""
             aria-hidden="true" ref="enableBreachCheckModal">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content k-modal-content">
              <div class="modal-header">
                <img src="./../../assets/img/left-nav/password-white.svg" class="icon-image"
                     alt="add password"
                >
                <h5 class="modal-title">
                  {{ $t("settings.passwordhealth.detail.modal.title") }}
                </h5>
                <div @click="state.passwordhealth.value.modal.instance.hide()" class="clickable">
                  <img src="./../../assets/img/common/x-lg-white.svg" class="icon-image"
                       alt="close dialog"
                  >
                </div>
              </div>
              <div class="modal-body">
                <div class="alert alert-danger" role="alert" v-for="error in state.passwordhealth.value.modal.errors">
                  {{ error }}
                </div>
                <div class="alert alert-success" role="alert"
                     v-for="success in state.passwordhealth.value.modal.success">
                  {{ success }}
                </div>
                {{ $t("settings.passwordhealth.detail.modal.body") }}
              </div>
              <div class="modal-footer">
                <div class="spinner-border" role="status" v-if="state.passwordhealth.value.modal.interacting"></div>
                <button type="button" class="btn btn-primary" @click="changeState">
                  {{ $t("settings.passwordhealth.detail.modal.actionButton") }}
                </button>
                <button type="button" class="btn btn-secondary text-white"
                        @click="state.passwordhealth.value.modal.instance.hide()">
                  {{ $t("settings.passwordhealth.detail.modal.closeButton") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
  </View>
</template>

<script>
import View from "@/components/Common/View";
import {onMounted, reactive, ref, toRefs, watchEffect} from "vue";
import {ROUTES} from "@/src/Config/Settings/routes";
import {useStore} from "vuex";
import Loading from "@/components/Common/Loading";
import _ from "lodash";
import LoadingField from "@/components/Common/LoadingField";
import {API_SERVICE, APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";
import Card from "@/components/Common/Card";
import moment from "moment";
import {Modal} from "bootstrap";

export default {
  name: "Settings",
  components: {Card, LoadingField, Loading, View},
  setup() {
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    /** @var {AppStorage} appStorage */
    const appStorage = container.get(APP_STORAGE);
    const isSaas = appStorage.isSaas();
    const store = useStore();
    const file = ref(null);
    const enableBreachCheckModal = ref(null);

    const cancelSubscriptionModal = ref(null);
    const state = reactive(
        {
          loading: true,
          deactivated: false,
          removed: false,
          selected: 'subscription',
          connection: {
            host: '',
            port: '',
            user_dn: '',
            password: '',
            base_dn: '',
            active_ts: {},
            create_ts: {},
          },
          subscription: {
            cancellation: {
              modal: null,
              confirmCancel: "",
              loading: false
            }
          },
          passwordhealth: {
            loading: true,
            value: false,
            modal: {
              instance: null,
              errors: [],
              success: [],
              interacting: false
            }
          }
        }
    );

    watchEffect(
        () => {
          switch (state.selected) {
            case 'ldap':
              getLdapConfig();
              break;
            case 'subscription':
              break;
            case 'passwordhealth':
              break;
            default:
              console.error('unknown operation ' + state.selected)
          }
        }
    )

    function cancelSubscription() {
      state.subscription.cancellation.modal = new Modal(cancelSubscriptionModal.value);
      state.subscription.cancellation.modal.show();
    }

    function requestCancel() {
      state.subscription.cancellation.loading = true;
      _.debounce(
          () => {
            apiService.post(
                ROUTES.requestCancellation()
                , {
                  user_id: store.state.user.id
                }
            )
                .then(
                    (response) => {
                      setTimeout(
                          () => {
                            state.subscription.cancellation.loading = false;
                            let cancelAt = response.data.cancelAt;
                            state.subscription.cancellation.confirmCancel = "Cancellation Confirmed. Your subscription ends: " + moment.unix(cancelAt).format("MMM Do YY");
                          }
                          , 1000
                      )
                    }
                )
                .catch(
                    (r) => {
                      console.error(r);
                      file.value = null;
                      state.subscription.cancellation.loading = false;
                    }
                )
          }, 500
      )();
    }

    function getLdapConfig() {
      state.loading = true;

      _.debounce(
          () => {
            apiService.get(ROUTES.getLdapConfig())
                .then(
                    (response) => {
                      state.loading = false;
                      state.connection = response.data.connection;
                    }
                )
                .catch(
                    (r) => {
                      file.value = null;
                      state.loading = false;
                      state.selected = null;
                    }
                )
          }, 500
      )();
    }

    function getLdapInfo() {
      if (state.connection.create_ts === null) return '';
      return "created on " + moment(state.connection.create_ts.date).format() + " and active since " + moment(state.connection.active_ts.date).format();
    }

    function deactivate() {
      _.debounce(
          () => {
            apiService.post(ROUTES.deactivate())
                .then(
                    (response) => {
                      state.loading = false;
                      state.deactivated = true;
                    }
                )
                .catch(
                    (r) => {
                      console.error(r);
                      file.value = null;
                      state.loading = false;
                    }
                )
          }, 500
      )();
    }

    function remove() {
      _.debounce(
          () => {
            apiService.post(ROUTES.remove())
                .then(
                    (response) => {
                      state.loading = false;
                      state.deactivated = true;
                      state.removed = true;
                    }
                )
                .catch(
                    (r) => {
                      console.error(r);
                      file.value = null;
                      state.loading = false;
                    }
                )
          }, 500
      )();
    }

    function loadBreachesState() {
      apiService.get(
          ROUTES.isBreachesActive()
      ).then(
          (r) => {
            state.passwordhealth.value = r.data.active;
            state.passwordhealth.loading = false;
          }
      )
    }

    onMounted(
        () => {
          //state.selected = isSaas ? 'subscription' : 'ldap';
          state.selected = 'passwordhealth';
          loadBreachesState();
        }
    )

    function onCheckboxClick() {
      state.passwordhealth.modal.instance = new Modal(enableBreachCheckModal.value);
      state.passwordhealth.modal.instance.show();
    }

    function changeState() {
      state.passwordhealth.modal.interacting = true;
      _.debounce(
          () => {
            apiService.post(
                ROUTES.changeState(),
                {
                  activate: state.passwordhealth.value
                }
            )
                .then(
                    (response) => {
                      state.passwordhealth.modal.interacting = false;
                      state.passwordhealth.modal.success.push("enabled");
                      state.passwordhealth.value = response.data.active;

                      setTimeout(
                          () => {
                            state.passwordhealth.modal.success = [];
                          },
                          9000
                      )
                    }
                )
                .catch(
                    (r) => {
                      state.passwordhealth.modal.interacting = false;
                      let message = "error :(";
                      if (r.response.status === 409) {
                        message = "already activated, can not activate again!";
                      }
                      state.passwordhealth.modal.errors.push(message)
                      state.passwordhealth.value = !state.passwordhealth.value;

                      setTimeout(
                          () => {
                            state.passwordhealth.modal.errors = [];
                          },
                          9000
                      )
                    }
                )
          }, 500
      )();
    }

    return {
      state: toRefs(state),
      getLdapInfo: getLdapInfo,
      deactivate: deactivate,
      remove: remove,
      cancelSubscription: cancelSubscription,
      requestCancel: requestCancel,
      cancelSubscriptionModal: cancelSubscriptionModal,
      enableBreachCheckModal: enableBreachCheckModal,
      isSaas: isSaas,
      isLdap: false,
      onCheckboxClick: onCheckboxClick,
      changeState: changeState
    }
  }
}
</script>

<style scoped>
.settings-container {
  box-sizing: border-box;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 0.7rem;
  background-color: #F8F9FA;
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.settings-loading {
  height: 3vh;
  width: 100%;
  margin-top: 1vh;
}

.profile-loading-square {
  height: 150px;
  width: 150px;
}

.profile-loading-long {
  height: 25px;
  width: 150px;
}
</style>
