<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 h100vh">
    <TopPanel></TopPanel>
    <div id="image"></div>
    <section class="h-100 resultpage-box">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2">Keestash</h1>
            <h4 class="text align-self-center p-2">Open Source password manager!</h4>
            <h4 class="text align-self-center p-2">Kee your passwords secure, organized and under your control.</h4>
            <div
                class="d-flex flex-column bg-white align-items-center text-center register-container-inner rounded justify-content-center">
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading">
                <div class="spinner-border" role="status"></div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading===false && state.found === true"
              >
                <h1>Congratulations!</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <div class="text-primary">
                  <p>You have successfully registered for Keestash.</p>
                </div>
                <div class="text-primary">
                  <p>Sign in and start using Keestash!</p>
                </div>
                <button class="btn btn-primary" @click="$router.push('/Login')">Sign In</button>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading===false && state.found === false"
              >
                <h1>No Subscription Found</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-slash-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                </svg>
                <div class="text-primary">
                  <p>We could not find a subscription. Please sign up or contact our support.</p>
                </div>
                <div class="text-primary">
                  <p>Sign up and start using Keestash!</p>
                </div>
                <button class="btn btn-primary" @click="$router.push('/Register')">Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Subscription/Result/routes";
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import TopPanel from "@/components/Common/TopPanel.vue";

export default {
  name: "ResultPage",
  components: {TopPanel},

  setup() {
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    const route = useRoute();
    const sessionId = route.query.session_id || null;

    const state = reactive(
        {
          subscribed: false,
          loading: true
        }
    );

    function isSubscribed() {
      apiService.post(
          ROUTES.getPaymentSubscribed()
          , {
            session_id: sessionId
          }
      )
          .then((response) => {
            state.loading = false;
            state.found = response.data.completed;
          })
          .catch(() => {
            state.loading = false;
          })
    }

    onMounted(
        () => {
          state.loading = true;
          isSubscribed();
        }
    )

    return {
      state: state
    }
  }
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 100vw;
  height: 10vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../../assets/img/4.svg");
}

.resultpage-box {
  z-index: 99999;
  color: white;
}

</style>