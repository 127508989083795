<template>
  <div
      class="modal fade"
      :class="{ show: isVisible }"
      role="dialog"
      @click.self="handleClose"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="handleClose" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>

        <div class="modal-footer">
          <button v-if="secondaryButtonText" type="button" class="btn btn-secondary" @click="onSecondaryClick">
            {{ secondaryButtonText }}
          </button>
          <button v-if="primaryButtonText" type="button" class="btn btn-primary" @click="onPrimaryClick">
            {{ primaryButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: 'Modal Title'
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    primaryButtonText: {
      type: String,
      default: ''
    },
    secondaryButtonText: {
      type: String,
      default: ''
    }
  },
  emits: ['close', 'primary-click', 'secondary-click'],
  setup(props, {emit}) {
    const visible = ref(props.isVisible);

    watch(() => props.isVisible, (newValue) => {
      visible.value = newValue;
    });

    const handleClose = () => {
      visible.value = false;
      emit('close');  // Emit the close event to the parent
    };

    const onPrimaryClick = () => {
      emit('primary-click');
    };

    const onSecondaryClick = () => {
      emit('secondary-click');
      handleClose();  // Close the modal when the secondary button is clicked
    };

    return {
      visible,
      handleClose,
      onPrimaryClick,
      onSecondaryClick
    };
  }
};
</script>

<style scoped>
/* For Bootstrap Modals to work correctly */
.modal.fade {
  display: none;
  transition: opacity 0.15s linear;
}

.modal.fade.show {
  display: block;
}
</style>
