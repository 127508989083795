/**
 * Keestash
 *
 * Copyright (C) <2021> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import {createI18n} from 'vue-i18n'
import {APP_STORAGE, ContainerFactory} from "@/src/Container/ContainerFactory";

function loadLocaleMessages() {
    const locales = require.context(
        "./",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const containerFactory = new ContainerFactory();
const container = containerFactory.create();
/** @var {AppStorage} apiService */
const appStorage = container.get(APP_STORAGE);
export default createI18n({
    locale: getLocale(appStorage),
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
    legacy: false
})

function getLocale(appStorage) {
    const browserLanguage = navigator.language.split('-')[0];
    const configuredLanguage = appStorage.getPrimaryLanguage();
    if (
        configuredLanguage === null
        || configuredLanguage === undefined
        || configuredLanguage === "undefined"
    ) {
        return browserLanguage;
    }
    return configuredLanguage;
}