<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0">
    <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 bg-primary">
      <TopPanel class="mb-3"></TopPanel>
    </div>
    <section class="h-100 register-box mt-5">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2 h1">{{ $t('toc.header') }}</h1>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.description') }}
            </div>
            <div class="d-flex flex-column register-container-inner rounded mt-3">
              {{ $t('toc.statement') }}
            </div>
            <h4 class="text align-self-center p-2 h4 mt-3">{{ $t('toc.generalUsage.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.generalUsage.content') }}
            </div>
            <h4 class="text align-self-center p-2 h4">{{ $t('toc.runtime.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.runtime.content') }}
            </div>
            <h4 class="text align-self-center p-2 h4">{{ $t('toc.liability.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.liability.content') }}
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              <ul class="list-group  mt-5 mb-5">
                <li class="list-group-item">{{ $t('toc.liability.acceptedLiability.1') }}</li>
                <li class="list-group-item">{{ $t('toc.liability.acceptedLiability.2') }}</li>
                <li class="list-group-item">{{ $t('toc.liability.acceptedLiability.3') }}</li>
                <li class="list-group-item">{{ $t('toc.liability.acceptedLiability.4') }}</li>
              </ul>
              {{ $t('toc.liability.content') }}
            </div>
            <h4 class="text align-self-center p-2 h4">{{ $t('toc.legal.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.legal.content') }}
            </div>
            <h4 class="text align-self-center p-2 h4">{{ $t('toc.others.title') }}</h4>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.others.content') }}
            </div>
            <div class="d-flex flex-column register-container-inner rounded">
              {{ $t('toc.others.costs') }}
            </div>
            <div class="d-flex flex-column register-container-inner rounded mb-5 mt-5">
              {{ $t('toc.lastchange') }}
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import TopPanel from "@/components/Common/TopPanel.vue";

export default {
  name: "TOC",
  components: {TopPanel}
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  width: 100vw;
  height: 50vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../assets/img/4.svg");
}

.h100vh {
  height: 100vh;
}

.register-box {
  z-index: 99999;
}

.register-container-inner {
  /*padding-left: 2rem;*/
  /*padding-right: 2rem;*/
  /*padding-top: 2rem;*/
  /*padding-bottom: 2rem;*/

  /*border-radius: 0.7rem;*/
  /*background-color: #F8F9FA;*/
  /*min-height: 2.5rem;*/
  /*box-shadow: 0px 2px 5.5px rgb(0 0 0 / 2%);*/
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
}

</style>