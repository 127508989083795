<template>
  <div class="d-flex flex-column container-fluid flex-grow-1 m-0 p-0 h100vh">
    <TopPanel></TopPanel>
    <div id="image"></div>
    <section class="h-100 resultpage-box">
      <header class="container h-100 ">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="d-flex flex-column">
            <h1 class="text align-self-center p-2">{{ $t('register.confirmation.title') }}</h1>
            <h4 class="text align-self-center p-2">{{ $t('register.confirmation.subTitle') }}</h4>
            <h4 class="text align-self-center p-2">{{ $t('register.confirmation.slogan') }}</h4>
            <div
                class="d-flex flex-column bg-white align-items-center text-center register-container-inner rounded justify-content-center">
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading">
                <div class="spinner-border" role="status"></div>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading===false && state.found === true"
              >
                <h1>{{ $t('register.confirmation.congratulations') }}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <div class="text-primary">
                  <p>{{ $t('register.confirmation.successMessage') }}</p>
                </div>
                <div class="text-primary">
                  <p>{{ $t('register.confirmation.cta') }}</p>
                </div>
                <button class="btn btn-primary" @click="$router.push('/Login')">{{
                    $t('register.confirmation.cta1')
                  }}
                </button>
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center congrats text-primary"
                   v-if="state.loading===false && state.found === false"
              >
                <h1>{{ $t('register.confirmation.errorMessage') }}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" height="200" fill="currentColor"
                     class="bi bi-slash-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                </svg>
                <div class="text-primary">
                  <p>{{ $t('register.confirmation.errorDetail') }}</p>
                </div>
                <div class="text-primary">
                  <p>{{ $t('register.confirmation.errorCta') }}</p>
                </div>
                <button class="btn btn-primary" @click="$router.push('/Register')">
                  {{ $t('register.confirmation.errorCta1') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>


<script>
import {API_SERVICE, ContainerFactory} from "@/src/Container/ContainerFactory";
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import TopPanel from "@/components/Common/TopPanel.vue";
import {ROUTES} from "@/src/Config/Register/routes";

export default {
  name: "Confirmation",
  components: {TopPanel},

  setup() {
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    const route = useRoute();
    const token = route.query.token || null;

    const state = reactive(
        {
          subscribed: false,
          loading: true
        }
    );

    function confirm() {
      apiService.post(
          ROUTES.confirmRegistration()
          , {
            token: token
          }
      )
          .then(() => {
            state.loading = false;
            state.found = true;
          })
          .catch((r) => {
            state.loading = false;
          })
    }

    onMounted(
        () => {
          state.loading = true;
          confirm();
        }
    )

    return {
      state: state
    }
  }
}
</script>

<style scoped>
#image {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  width: 100vw;
  height: 50vh;
  /* Deep blue */

  background: #081A51;
  z-index: 0;
  background-image: url("./../../assets/img/4.svg");
}

.h100vh {
  height: 100vh;
}

.register-box {
  z-index: 99999;
}

.register-container-inner {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-radius: 0.1rem;
  background-color: #F8F9FA;
  min-height: 30rem;
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#register-button {
  width: 100%;
}

</style>