<template>
  <div class="container-fluid text-center align-middle flex-grow-1 h-100 justify-content-center d-flex flex-column">
    <div class="spinner-border text-center align-middle justify-content-center align-self-center" role="status"
         id="open-lock">
    </div>

    <div>Loading</div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingPasswords"
}
</script>

<style scoped>

</style>