<template>
  <router-view></router-view>
</template>

<script>
import Login from "@/components/Login/Login";

export default {
  name: 'App',
  components: {
    Login
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1vw;
  min-height: 100vh;
  background-color: #FFFFFF !important;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
</style>
